import React from "react"
import { Link, graphql, PageRendererProps } from "gatsby"
import cn from 'classnames'

import Layout from "../templates/layout"
import { File, SanitySummaryImage, SanitySummaryRow, SanityVisualSummary, SanityVisualSummaryConnection } from "../types/server-types"
import { SEO, ResponsiveImage } from "../partials"
import { Container, Column, Columns, FadeIn, Heading, Icon, AnchorButton } from "../components"
import { marginClass, paddingClass } from "../library/spacing"

export interface PageProps<T extends {} = any> extends PageRendererProps {
  data: T
}

interface IndexPageData {
  robot: File
  visualSummary: SanityVisualSummaryConnection
}

const IndexPage: React.FunctionComponent<PageProps<IndexPageData>> = (props) => {
  const assets = props.data.visualSummary.nodes[0]?.images

  function renderRow(row: SanitySummaryRow) {
    return (
      <Container maxWidth="xxl" className="image-grid">
        <Columns justify="center">
          {row.columns.map(column => (
            <div className={cn('image-grid__col', { '-half': row.columns.length > 1 })} key={column._key}>
              {column.images.map(summaryImage => {
                const responsiveImage = summaryImage.image
                const baseAsset = responsiveImage.xs.asset.fluid
                return (
                  <FadeIn animation="fade-in">
                    <div
                      key={summaryImage._key}
                      className={cn('image-grid__item', summaryImage.shape ? '-' + summaryImage.shape : '-auto')}
                    >
                      <ResponsiveImage image={responsiveImage} />
                      <div className="footer">
                        <Columns align="middle">
                          <Column>
                            <div className="caption" style={{ color: summaryImage.image.xs.asset.metadata?.palette?.dominant?.title }}>
                              {summaryImage.title && <strong>{summaryImage.title} – </strong>}
                              {summaryImage.caption}
                            </div>
                          </Column>
                          <Column span="shrink">
                            {summaryImage.project &&
                              <AnchorButton
                                href={`projects/${summaryImage.project.slug.current}`}
                                shape="circular"
                                size="small"
                              >
                                View Project <Icon name="ArrowRightSm" />
                              </AnchorButton>
                            }
                          </Column>
                        </Columns>
                      </div>
                    </div>
                  </FadeIn>
                )
              })}
            </div>
          ))}
        </Columns>
      </Container>
    )
  }

  function renderFullImage(image: SanitySummaryImage) {
    return (
      <FadeIn animation="fade-in" >
        <div key={image._key} className={cn(marginClass([11, null]))}>
          <ResponsiveImage image={image.image} className={cn('image-grid__full')} />
          {image.caption &&
            <Container maxWidth="xxl" className={marginClass([6, null, null])}>
              <p className="c--gray-light">
                {image.title && <strong>{image.title}</strong>}
                {image.caption}
              </p>
            </Container>
          }
        </div>
      </FadeIn>
    )
  }

  function renderImages(images: SanityVisualSummary['images']) {
    if (!images) return null

    return images.map(image => {
      switch (image._type) {
        case 'summaryImage':
          return renderFullImage(image)
        case 'summaryRow':
          return renderRow(image)
        default:
          return null
      }
    })
  }


  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <Container maxWidth="xl">
          <Columns align="middle" justify="center" style={{ minHeight: 'calc(100vh - 48px)' }}>
            <Column span={{ xs: 12, sm: 10 }} className="t--center">
              <FadeIn.Observer tagName="div">
                <FadeIn.Child animation="fade-in" delay={500}>
                  <img src={props.data.robot.publicURL} className={marginClass([null, null, 4])} width={100} alt="" />
                </FadeIn.Child>
                <FadeIn.Child>
                  <Heading size={6} className="c--late-night pb-6">
                    Well Done is the superfluous<br className="hidden-sm-down" /> moniker of <Link to="about">Adam Volkman</Link>, another soggy Portland <Link to="visuals">creative</Link>.
                </Heading>
                </FadeIn.Child>
              </FadeIn.Observer>
            </Column>
          </Columns>
        </Container>
      </div>
      <div id="visual-summary">
        {renderImages(assets)}
      </div>
      <Container maxWidth="lg" className={marginClass([12, null])}>
        <Columns className="t--center">
          <Column>
            <Heading size={4} margin={[null, null, 6]}>But Wait, There's more!</Heading>

            <p><AnchorButton href="/projects" intent="primary">View Projects</AnchorButton></p>
          </Column>
        </Columns>
      </Container>

      <div className={cn("bg--jazzy", paddingClass([12, null]))}>
        <Container>
          <Columns>
            <Column className="t--center">
              <Link to="about" className={cn('c--white', 'f--5', 'heading')}>
                Meet the man to blame for this.&nbsp;
                <Icon name="ArrowRightLg" />
              </Link>
            </Column>
          </Columns>
        </Container>
      </div>
    </Layout >
  )
}

export default IndexPage

export const query = graphql`
  fragment fluidImage on SanityImageAsset {
    fluid(maxWidth: 1800) {
      aspectRatio
      src
      srcSet
      sizes
    }
  }

  fragment summaryImage on SanitySummaryImage {
    _key
    _type
    caption
    title
    project {
      slug {
        current
      }
    }
    image {
      xs {
        asset {
          ...fluidImage
          metadata {
            palette {
              dominant {
                foreground
                background
                title
              }
            }
          }
        }
      }
      overrides {
        width
        image {
          asset {
            ...fluidImage
          }
        }
      }
    }
    shape
  }
  query HomePageQuery{
    robot: file(relativePath: { eq: "intro-robot.gif" }) {
      publicURL
    }
    visualSummary: allSanityVisualSummary(filter: {_id: {eq: "visualSummary"}}) {
      nodes {
        title
        images {
          ... on SanitySummaryImage {
            ... summaryImage
          }
          ... on SanitySummaryRow {
            columns {
              images {
                ... summaryImage
              }
            }
            _type
            _key
          }
        }
      }
    }
  }
`

